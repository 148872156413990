import { defineStore } from 'pinia';
import type { UnitAttributes } from '~/types';
import { plural } from '@upfrontjs/framework';

interface State {
    units: UnitAttributes[];
}

const useUnitStore = defineStore('unit', {
    state: (): State => {
        return {
            units: []
        };
    },

    getters: {
        relativeUnits(): UnitAttributes[] {
            return this.units.filter(unit => unit.isRelative === true);
        },
        absoluteUnits(): UnitAttributes[] {
            return this.units.filter(unit => unit.isRelative === false);
        }
    },

    actions: {
        getDisplayName(unit: UnitAttributes | UnitAttributes['id'], amount?: number): string {
            unit = typeof unit === 'number' ? this.units.find(u => u.id === unit)! : unit;
            const name = unit.name ?? unit.abbreviation;

            if (unit.isRelative === true || !amount) {
                return name;
            }

            return amount > 1 ? plural(name) : name;
        }
    }
});

export default useUnitStore;
